import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './@core/_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import {OutsideClickService} from "./shared";

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private outsideClick : OutsideClickService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.modeService.init();
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    // Check if the clicked element or any of its ancestors have the class 'selectLogos'
      const targetElement = event.target as HTMLElement;
      if (targetElement && !targetElement.closest('.selectLogos')) {
          this.outsideClick.outsideClick$.next(true);
      }
  }
}
