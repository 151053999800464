import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild{
  /**
   * @param authService : AuthService
   */
  constructor(private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this.authService.currentUserValue;
    if (currentUser && !this.authService.isTokenExpired(this.authService.getAuthFromLocalStorage()?.expiresIn)) {
      /**
       * logged in so return true
       */
      return true;
    }
    /**
     * not logged in so redirect to login page with the return url
     */
    this.authService.logout();
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
