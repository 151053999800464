import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../auth.service";
import {Permission} from "../../../administration/role-management/models/Permission.model";

@Injectable({
  providedIn: 'root'
})

export class LicenseAdKeeperGuard {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.license_features && currentUser.license_features.length > 0) {
      const hasAdKeeperFeature = currentUser.license_features.some((lf:any) => lf.name.includes('adkeeper'))

      let permissionsNecessaryForThePath = route.data.permissions as Permission[];
      let IfUserHasExpectedPermissions = this.authService.checkIfUserHasExpectedPermissions(permissionsNecessaryForThePath);
      if(!permissionsNecessaryForThePath){
        IfUserHasExpectedPermissions=true;
      }
      if(hasAdKeeperFeature && IfUserHasExpectedPermissions){
        return true
      } else {
        this.router.navigate(['/error/401']);
        return false;
      }
    }

    // If not authorized, redirect to 401 error page
    this.router.navigate(['/error/401']);
    return false;
  }

}
