import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {AuthModel} from "../models/auth.model";
import {Router} from "@angular/router";
import {DialogService} from "../../../shared/services/dialog-service/dialog-service";
import {LoaderService, SwalNotificationsService} from "../../../shared";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  /**
   * @param router
   * @param dialogService
   * @param loaderService
   * @param swalNotificationsService
   */
  constructor(private router: Router,
              private loaderService : LoaderService,
              ) {}

  /**
   * Method to check if a token has expired based on the expiration date;
   * @param expiresIn : string
   */
  isTokenExpired(expiresIn: string | undefined | Date): boolean {
    if (!expiresIn) return true; /** Token not present, consider it expired */

    /** Parse expiration date from string */
    const expirationDate = new Date(expiresIn);

    /** Get current date */
    const currentDate = new Date();

    /** Compare expiration date with current date */
    return expirationDate < currentDate;
  }

  public getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem('cp');
    this.loaderService.hide();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }
}
