import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomClassesService {

  private readonly classesList: string[] = [
    'bg-light-primary text-primary',
    'bg-light-info text-info',
    'bg-light-warning text-warning',
    'bg-light-success text-success',
    'bg-light-danger text-danger',
    // Add more classes as needed
  ];
  constructor() { }

  // Function to get random classes
  getRandomClasses(): string {
    return this.classesList[Math.floor(Math.random() * this.classesList.length)];
  }
}
