import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class SwalNotificationsService {
  private activeSwals: any[] = [];

  constructor() { }

  private showNotification(options: any) {
    const swalInstance = Swal.fire(options);
    this.activeSwals.push(swalInstance);
    swalInstance.then(() => {
      // Remove the instance from activeSwals when it's closed
      this.activeSwals = this.activeSwals.filter(s => s !== swalInstance);
    });
    return swalInstance;
  }

  successNotification(msg: string) {
    return this.showNotification({
      title: 'Success',
      text: msg,
      icon: 'success',
      confirmButtonColor: '#009ef7',
      confirmButtonText: 'OK',
    });
  }

  warningNotification(msg: string) {
    return this.showNotification({
      title: 'Warning',
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#009ef7',
      confirmButtonText: 'OK',
    });
  }

  errorNotification(msg: string) {
    return this.showNotification({
      title: 'Error',
      text: msg,
      icon: 'error',
      confirmButtonColor: '#009ef7',
      confirmButtonText: 'OK',
    });
  }

  closeAllSwals() {
    this.activeSwals.forEach(swalInstance => {
      swalInstance.close();
    });
    this.activeSwals = [];
  }
}
