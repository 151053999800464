import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RecalculateTableService {
  public recalculateTable$: Subject<boolean> = new Subject<boolean>();

  constructor() { }
}
