import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../auth.service";
import {Permission} from "../../../administration/role-management/models/Permission.model";

@Injectable({
  providedIn: 'root'
})

export class RoleGuard {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let PermissionsWithAccessToAdministration = route.data.permissions as Permission[];
    if (this.authService.checkIfUserHasExpectedPermissions(PermissionsWithAccessToAdministration)) {
      return true;
    }
    this.router.navigate(['/error/401']);
    return false;

  }

  /**
   * Do not use.
   * @param route
   * @param state
   */

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let PermissionsWithAccessToAdministration = route.data.permissions as Permission[];
    if (this.authService.checkIfUserHasExpectedPermissions(PermissionsWithAccessToAdministration)) {
      return true;
    }
    this.router.navigate(['/error/401']);
    return false;

  }

}
