import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {finalize} from "rxjs/operators";
import {LoaderService, SwalNotificationsService} from "../../../../shared";
import {TokenService} from "../token.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  /**
   *
   * @param loaderService
   * @param TokenExpiredService
   * @param swalNotifs
   */
  constructor(private loaderService : LoaderService,
              private swalNotifs: SwalNotificationsService,
              private TokenExpiredService: TokenService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const showLoader = !request.headers.has('Skip-Loader');


    /**
     * Show loader
     */

    if (request.url.includes('lunchChecks')) this.loaderService.requestMonitoringStarted();
    else {
      if(showLoader)
        this.loaderService.show();
    }

    /**
     * Skip interception for these URL requests
     */

    if (request.url.includes('/login') ||
        request.url.includes('/sign-up') ||
        request.url.includes('/contact-us/send') ||
        request.url.includes('/forgot-password') ||
        request.url.includes('/new-password') ||
        request.url.endsWith('/user') ||
        request.url.includes('/check-token-expiration')) {
      return next.handle(request).pipe(
          finalize(() => {
            /**
             * Hide loader after request is completed
             */

            if(request.url.includes('lunchChecks')) this.loaderService.requestMonitoringEnded();
            else {
              if(showLoader)
                this.loaderService.hide();
            }
          })
      );
    }

    /**
     * Retrieve token from localStorage;
     */
    const lsValue = localStorage.getItem(this.authLocalStorageToken);
    let token = '';
    if (lsValue) {
      token = JSON.parse(lsValue).authToken;
    }

    /**
     * Check if the token is expired
     */
    if (this.TokenExpiredService.isTokenExpired(this.TokenExpiredService.getAuthFromLocalStorage()?.expiresIn)) {
      this.TokenExpiredService.logout();
      setTimeout(()=>{this.swalNotifs.closeAllSwals()},0)
      return throwError('Session expired');
    }
    /**
     * Clone the request and add the token header if token is available
     */
    let modifiedRequest = request;
    if (token) {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    /**
     * Pass the modified request instead of the original request
     */
    return next.handle(modifiedRequest).pipe(
      finalize(() => {
        /**
         * Hide loader after request is completed
         */

        if(request.url.includes('lunchChecks')) this.loaderService.requestMonitoringEnded();
        else {
          if(showLoader)
            this.loaderService.hide();
        }
      })
    )
  }
}
