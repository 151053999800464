import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {MediaType} from "../../../modules/administration";

const API_URL = `${environment.apiUrl}/advertiser`;

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService {

  /**
   * @param http : HttpClient
   */
  constructor(private http: HttpClient) {
  }


  getAdvertisersByPartners(ids: number[], mediaType : MediaType): Observable<any[]> {
    let headers = new HttpHeaders();
    headers = headers.set("Skip-Loader",'true');
    return this.http.post<any[]>(`${API_URL}/partnerIds/${mediaType}`, ids,{headers});
  }

  /**
   * @param partnerId : number
   * @param userId : string
   */
  getAdvertisersByPartnerIdAndUserId(partnerId:number,userId:string) {
    return this.http.get<any[]>(`${API_URL}?partnerId=${partnerId}&&userId=${userId}`);
  }
}
