import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

const API_URL = `${environment.apiUrl}/campaigns`;

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  /**
   * @param http : HttpClient
   */
  constructor(private http: HttpClient) {
  }


  getCampaigns(dvRequest:any) {
    return this.http.post<any>(`${API_URL}/get-campaigns`, dvRequest);
  }
}
