import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private progressBarCount = 0;
  private progressBar$ = new BehaviorSubject<string>('');
  public padsSpinner$ : Observable<boolean>;
  private padsSpinner$Subject: BehaviorSubject<boolean>;
  private loaderCount : number = 0 ;
  constructor() {
    this.padsSpinner$Subject = new BehaviorSubject<boolean>(false); // Initialize with false
    this.padsSpinner$ = this.padsSpinner$Subject.asObservable();
  }

  getLoaderState(): Observable<boolean> {
    return this.padsSpinner$;
  }


  show() {
    if (this.loaderCount === 0) {
      this.padsSpinner$Subject.next(true);
    }
    this.loaderCount++;
  }

  hide() {
    this.loaderCount--;
    if (this.loaderCount <= 0) {
      this.loaderCount = 0;
      this.padsSpinner$Subject.next(false);
    }
  }
  getProgressBarObserver(): Observable<string> {
    return this.progressBar$.asObservable();
  }
  requestMonitoringStarted(){
    if(++this.progressBarCount > 0) this.progressBar$.next('start');
  }

  requestMonitoringEnded(){

    if(this.progressBarCount === 0 || --this.progressBarCount === 0) this.progressBar$.next('stop');
  }
}
