import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../auth.service";

@Injectable({
    providedIn: 'root'
})

export class MediaGuard {
    hasDV: boolean = false;
    hasDSP: boolean = false;
    hasFB: boolean = false;
    hasXDR: boolean = false;
    hasTKTK: boolean = false;

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        const component: string = route.data.component;
        if (currentUser && currentUser.license_features && currentUser.license_features.length > 0) {
            this.hasDV = currentUser?.license_features.some((lf) => {
                return lf.name.includes('dv360')
            });
            this.hasDSP = currentUser?.license_features.some((lf) => {
                return lf.name.includes('amz')
            });
            this.hasFB = currentUser?.license_features.some((lf) => {
                return lf.name.includes('fbads')
            });
            this.hasXDR = currentUser?.license_features.some((lf) => {
                return lf.name.includes('xandr')
            });
            this.hasTKTK = currentUser?.license_features.some((lf) => {
                return lf.name.includes('tiktok')
            });

            if (component === 'dv360') {
                this.mediaToCheck(this.hasDV)
            } else if (component === 'amazon') {
                this.mediaToCheck(this.hasDSP)
            } else if (component === 'fbads') {
                this.mediaToCheck(this.hasFB)
            } else if (component === 'xandr') {
                this.mediaToCheck(this.hasXDR)
            }else if (component === 'tiktok') {
                this.mediaToCheck(this.hasTKTK)
            } else if (component === 'grouping') {
                if (this.hasDSP && !this.hasDV && !this.hasFB && !this.hasXDR && !this.hasTKTK) {
                    this.router.navigate(['/error/401']);
                    return false;
                }
            }
        } else {
            // If not authorized, redirect to 401 error page
            this.router.navigate(['/error/401']);
            return false;
        }
    }

    public mediaToCheck(media: boolean) {
        if (media) {
            return true
        } else {
            this.router.navigate(['/error/401']);
            return false;
        }
    }
}
