import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';
import {DynamicRole} from "../../../administration/role-management/models/DynamicRole.model";
import {lastValueFrom} from "rxjs";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {
  private role: DynamicRole | undefined;

  /**
   * @param authService : AuthService
   */
  constructor(private authService: AuthService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = await lastValueFrom(this.authService.currentUserValueFromDB());

    if (!this.role) {
      this.role = currentUser?.roles[0];
    }
    if (currentUser && !this.authService.isTokenExpired(this.authService.getAuthFromLocalStorage()?.expiresIn) && "BLOCKED" != currentUser.status && this.role?.name === currentUser.roles[0].name) {
      /**
       * logged in so return true
       */
      return true;
    }
    /**
     * not logged in so redirect to login page with the return url
     */
    this.authService.logout();
    this.role = undefined;
    return false;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
