import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../auth.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationGuard {
  token: string = '';

  /**
   * @param authService : AuthService
   * @param router : Router
   */
  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /**
     *   Extract the token from the query parameters of the current route
     */
    this.token = state.root.queryParams['token'];
    /**
     *   If token is missing, navigate to error page and return false
     */
    if (!this.token) {
      this.router.navigate(['/error/404'])
      return false;
    }
    /**
     *   Create a new observable to handle the asynchronous check of token expiration
     */
    return new Observable(observer => {
      /**
       *  Call authService's checkTokenExpiration method, passing the token
       */
      this.authService
        .checkTokenExpiration(this.token)
        .subscribe({
          next: (res) => {
            if (res.body) {
              observer.next(true);
              observer.complete();
            } else {
              this.router.navigate(['/error/expired-link']);
              observer.next(false);
              observer.complete();
            }
          },
          error: (err) => {
            this.router.navigate(['/error/500']);
            observer.next(false);
            observer.complete();
          }
        });
    });
  }

}
