import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {

  constructor() { }

  /**
   * This will return the initials of each part of the full name, separated by a space
   * @param fullName
   */
  getInitials(fullName : string): string {
    const parts = fullName.split(' ');
    let initials = '';
    parts.forEach((part,index) => {
      if (part && index<2) {
        initials += part[0];
      }
    });
    return initials.toUpperCase();
  }
}
