import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, take, throwError} from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {catchError, finalize, switchMap} from "rxjs/operators";
import {LoaderService, SwalNotificationsService} from "../../../../shared";
import {TokenService} from "../token.service";
import {AuthHTTPService} from "../auth-http";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private loaderService: LoaderService,
              private swalNotifs: SwalNotificationsService,
              private tokenExpiredService: TokenService,
              private authHTTPService: AuthHTTPService  // Inject your service here to fetch user data
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const showLoader = !request.headers.has('Skip-Loader');

      /**
     * Show loader
     */
    if (request.url.includes('lunchChecks')) {
      this.loaderService.requestMonitoringStarted();
    } else {
      if (showLoader) {
        this.loaderService.show();
      }
    }

    /**
     * Skip interception for specific URLs (login, sign-up, etc.)
     */
    if (request.url.includes('/login') ||
        request.url.includes('/sign-up') ||
        request.url.includes('/contact-us/send') ||
        request.url.includes('/forgot-password') ||
        request.url.includes('/new-password') ||
        request.url.endsWith('/user') ||
        request.url.includes('/check-token-expiration')) {
      return next.handle(request).pipe(
          finalize(() => {
            if (request.url.includes('lunchChecks')) {
              this.loaderService.requestMonitoringEnded();
            } else {
              if (showLoader) {
                this.loaderService.hide();
              }
            }
          })
      );
    }

    /**
     * Retrieve token from localStorage;
     */
    const lsValue = localStorage.getItem(this.authLocalStorageToken);
    let token = '';
    if (lsValue) {
      token = JSON.parse(lsValue).authToken;
    }

    /**
     * Previous role of the user
     */
   let role =  localStorage.getItem('role')

    /**
     * Fetch fresh user data before proceeding with the request
     */
    return this.authHTTPService.getUserByToken(token).pipe(
        take(1),
        switchMap(user => {
          if (user && user.status !== 'BLOCKED' &&
              !this.tokenExpiredService.isTokenExpired(this.tokenExpiredService.getAuthFromLocalStorage()?.expiresIn) &&
              role === user.roles[0].name) {
            /**
             * If the user is not blocked, proceed with the request
             */
            let modifiedRequest = request;
            if (token) {
              modifiedRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`
                }
              });
            }

            return next.handle(modifiedRequest).pipe(
                finalize(() => {
                  if (request.url.includes('lunchChecks')) {
                    this.loaderService.requestMonitoringEnded();
                  } else {
                    if (showLoader) {
                      this.loaderService.hide();
                    }
                  }
                })
            );
          } else {
            /**
             * If the user is blocked, log them out and throw an error
             */
            this.tokenExpiredService.logout();
            setTimeout(() => { this.swalNotifs.closeAllSwals(); }, 0);
            return throwError('User is blocked, session expired');
          }
        }),
        catchError((err) => {
          /**
           * Handle error when fetching the user fails.
           */
            if(err.url.endsWith('/user')){
                this.tokenExpiredService.logout();
                setTimeout(() => { this.swalNotifs.closeAllSwals(); }, 0);
            }
            this.loaderService.hide();
            this.loaderService.requestMonitoringEnded();
            return throwError(err);
        })
    );
  }
}
