export const environment = {
  production: false,
  appVersion: 'v1.1.1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://testing.adsecura.com/api',
  baseUrl: 'https://testing.adsecura.com',
  clientIdAmazon: 'amzn1.application-oa2-client.39ec4c3163724377989d028e2127d61b',
  appName: 'AdSecura',
  clientId: '135305998834-0io1f9f3jil67lgktubm2euf4vdqnqb2.apps.googleusercontent.com'
};
