import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectContainsSearchTermService {

  constructor() { }
  objectContainsSearchTerm(obj: any, searchTerm: string , keysToSearch : string[]): boolean {
    for (const key of keysToSearch) {
      const valueToCompare = obj[key];
      // check for a match
      if (
        valueToCompare &&
        valueToCompare
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        // found match, return true to add to result set
        return true;
      }
    }
    return false;
  }
}
