import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from "../../services/loader/loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit{
  public show: boolean = false;
  /**
   *
   * @param cdRef
   * @param loaderService
   */
  constructor(private cdRef: ChangeDetectorRef,
              private loaderService : LoaderService
  ) {}

  ngOnInit(): void {
    this.getLoaderState()
    }
  getLoaderState(){
     this.loaderService.getLoaderState().subscribe((state) =>{
       this.show = state;
       this.cdRef.detectChanges();
     })
  }
}
